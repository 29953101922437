import { inject, Injectable } from '@angular/core';
import { NewsService } from '@features/news/services/news.service';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ArticleCard } from '@shared/types';
import { exhaustMap, pipe, tap } from 'rxjs';

type HomeNewsStateType = {
  readonly articles: readonly ArticleCard[];
  readonly isFreshNewsLoading: boolean;
};

const initalState: HomeNewsStateType = {
  articles: [],
  isFreshNewsLoading: false,
};

@Injectable()
export class HomeFreshNewsState {
  readonly #newsService = inject(NewsService);
  readonly #freshNewsState = signalState<HomeNewsStateType>(initalState);

  readonly articles = this.#freshNewsState.articles;
  readonly isFreshNewsLoading = this.#freshNewsState.isFreshNewsLoading;

  readonly loadNews = rxMethod<void>(
    pipe(
      tap(() => patchState(this.#freshNewsState, { isFreshNewsLoading: true })),
      exhaustMap(() => {
        return this.#newsService.getFeaturedNews().pipe(
          tapResponse({
            next: (news) => patchState(this.#freshNewsState, { articles: news }),
            error: console.error,
            finalize: () => patchState(this.#freshNewsState, { isFreshNewsLoading: false }),
          })
        );
      })
    )
  );
}
